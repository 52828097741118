<template>
  <div>
    <h1>Ajouter un Film :</h1>
    <v-text-field
      id="idFilm"
      v-model="id"
      outlined
      dense
      placeholder="ID"
      hide-details
    ></v-text-field>
    <v-container fluid>
      <v-row>
        <v-col>
          <v-btn
            color="primary"
            @click="sendData"
          >
            Enregistrer
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-snackbar
      v-model="snackbar.enable"
      :timeout="snackbar.timeout"
      :color="snackbar.color"
    >
      {{ snackbar.text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar.enable = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      snackbar: {
        enable: false,
        text: null,
        color: null,
        timeout: 2000,
      },
      token: localStorage.getItem('token') || null,
      id: null,
    }
  },
  methods: {
    async sendData() {
      await this.axios({
        method: 'post',
        url: 'https://bultimate.tk/newFilm',
        data: {
          id: this.id,
        },
        headers: { authorization: this.token },
      }).catch(e => {
        this.snackbar.text = e.toString()
        this.snackbar.color = 'error'
        this.snackbar.enable = true
      }).then(res => {
        if (res.data.error) {
          this.snackbar.text = res.data.error.toString()
          this.snackbar.color = 'error'
          this.snackbar.enable = true
        } else {
          this.snackbar.text = res.data.toString()
          this.snackbar.color = 'success'
          this.snackbar.enable = true
        }
      })
    },
  },
}
</script>
