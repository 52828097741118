import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: null,
    anime: {
      addEp: JSON.parse(localStorage.getItem('addEp')) || {
        id: null,
        season: null,
        ep: null,
        stream: null,
        provider: null,
        source: true,
      },
    },
    film: {
      addLink: JSON.parse(localStorage.getItem('addLink')) || {
        id: null,
        stream: null,
        provider: null,
        source: null,
      },
    },
  },
  mutations: {
    clear() {
      localStorage.clear()
    },
    set(state, key) { // this.$store.commit('set', key)
      if (key.name === 'addEp') localStorage.setItem('addEp', JSON.stringify(key))
      if (key.name === 'addLink') localStorage.setItem('addLink', JSON.stringify(key))
    },
  },
  actions: {},
  modules: {},
})
