import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'dashboard',
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
  },
  {
    path: '/anime/add',
    name: 'anime-add',
    component: () => import('@/views/anime/add.vue'),
  },
  {
    path: '/anime/add-ep',
    name: 'anime-add-ep',
    component: () => import('@/views/anime/add-ep.vue'),
  },
  {
    path: '/film/add',
    name: 'film-add',
    component: () => import('@/views/film/add.vue'),
  },
  {
    path: '/film/add-film-link',
    name: 'add-film-link',
    component: () => import('@/views/film/add-film-link.vue'),
  },
  {
    path: '/account',
    name: 'account',
    component: () => import('@/views/pages/account-settings/AccountSettings.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/pages/Login.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/pages/Register.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
