<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <h1>Ajouter un Anime :</h1>
          <v-text-field
            id="idAnime"
            v-model="id"
            outlined
            dense
            placeholder="ID"
            hide-details
          ></v-text-field>
          <br>
          <v-btn
            color="primary"
            @click="sendData"
          >
            Enregistrer Nouvel anime
          </v-btn>
        </v-col>
        <v-col
          cols="25"
          md="9"
        >
          <h1>Data :</h1>
          <v-textarea
            v-model="data"
            outlined
            name="input-7-4"
            label="Les données ici :"
            value=""
          ></v-textarea>
          <h2>Saison :</h2>
          <v-text-field
            id="season"
            v-model="season"
            outlined
            dense
            placeholder="Season"
            hide-details
          ></v-text-field>
          <br>
          <v-btn
            elevation="10"
            @click="copyCrunchyrollScript"
          >
            Copier Script Chrunchyroll
          </v-btn>
          <v-btn
            elevation="10"
            @click="copyVostfreeScript"
          >
            Copier Script Vostfree
          </v-btn>
          <v-btn
            color="primary"
            @click="sendVoled"
          >
            Enregistrer Data
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar
      v-model="snackbar.enable"
      :timeout="snackbar.timeout"
      :color="snackbar.color"
    >
      {{ snackbar.text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar.enable = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      snackbar: {
        enable: false,
        text: null,
        color: null,
        timeout: 2000,
      },
      token: localStorage.getItem('token') || null,
      data: null,
      season: null,
      id: null,
    }
  },
  methods: {
    copyCrunchyrollScript() {
      const content = `
let allEps = {}

$(".portrait-grid > li").each(function () {
    let lnk = $(this).attr("id");

    let ep = parseInt($(this).find("span").text().replace("Épisode", "").trim());
    if (!ep) return;

    let objEP = {
        [ep]: {
            crunchyroll: lnk.replace('showview_videos_media_', '').trim()
        }
    }

    Object.assign(allEps, objEP);
});

console.log(allEps)`

      navigator.clipboard.writeText(content)
        .then(() => {
          console.log('Text copied to clipboard...')
        }).catch(err => {
          console.log('Something went wrong', err)
        })
    },
    copyVostfreeScript() {
      const content = `
let currentEP = 1;
let maxEP = parseInt($( "div .jq-selectbox__dropdown ul:last-child" ).children().last().html().split(' ').pop());

let allEPS = {}

function changeSeria(number, ep) {
        $(".button_box").hide();
        $("#" + number).show();
        $(".button_box div").removeClass("nower");
        $("#" + number + " div:first-child").addClass("nower");

        let OBJ_EP = {}

        $("#"+number).children().each(function () {
            let lnk = $(this).attr("id");
            let playerName = $(this).attr("class").replace('nower', '').replace('new_player_', '').trim()
            let objEP = {
                [playerName]: $("#content_" + lnk).html()
            }

            Object.assign(OBJ_EP, objEP);
        });

        let actualEP = {
            [ep]: OBJ_EP
        }

        Object.assign(allEPS, actualEP)

}

for (let ep = currentEP; ep < maxEP + 1; ep++) {
    changeSeria("buttons_"+ep.toString(), ep);
}

console.log(allEPS)`

      navigator.clipboard.writeText(content)
        .then(() => {
          console.log('Text copied to clipboard...')
        }).catch(err => {
          console.log('Something went wrong', err)
        })
    },
    async sendData() {
      await this.axios({
        method: 'post',
        url: 'https://bultimate.tk/newAnime',
        data: {
          id: this.id,
        },
        headers: { authorization: this.token },
      }).catch(e => {
        this.snackbar.text = e.toString()
        this.snackbar.enable = true
      }).then(res => {
        if (res.data.error) {
          this.snackbar.text = res.data.error.toString()
          this.snackbar.enable = true
        }
      })
    },
    async sendVoled() {
      await this.axios({
        method: 'post',
        url: 'https://bultimate.tk/addAnimeDB',
        data: {
          id: this.id,
          data: this.data,
          season: this.season,
        },
        headers: { authorization: this.token },
      }).catch(e => {
        this.snackbar.text = e.toString()
        this.snackbar.color = 'error'
        this.snackbar.enable = true
      }).then(res => {
        if (res.data.error) {
          this.snackbar.text = res.data.error.toString()
          this.snackbar.color = 'error'
          this.snackbar.enable = true
        } else {
          this.snackbar.text = res.data.toString()
          this.snackbar.color = 'success'
          this.snackbar.enable = true
        }
      })
    },
  },
}
</script>
