<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <h1>Film : </h1>
          <v-text-field
            v-model="id"
            outlined
            dense
            placeholder="ID"
            hide-details
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <h1>Stream :</h1>
          <v-text-field
            v-model="stream"
            outlined
            dense
            placeholder="Stream"
            hide-details
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <h1>Provider :</h1>
          <v-select
            v-model="provider"
            :items="items"
            :rules="[v => !!v || 'Item is required']"
            required
          ></v-select>
        </v-col>
        <v-col
          cols="10"
          md="2"
        >
          <h1>Source :</h1>
          <v-switch
            v-model="source"
            inset
          ></v-switch>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid>
      <v-row>
        <v-col>
          <v-btn
            color="primary"
            @click="sendData"
          >
            Enregistrer
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-snackbar
      v-model="snackbar.enable"
      :timeout="snackbar.timeout"
      :color="snackbar.color"
    >
      {{ snackbar.text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar.enable = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      snackbar: {
        enable: false,
        color: null,
        text: null,
        timeout: 2000,
      },
      token: localStorage.getItem('token') || null,
      ...this.$store.state.film.addLink,
      items: [
        'VOE',
        'STREAMTAPE',
        'YTB',
        'CRUNCHYROLL',
        'WAKANIM',
        'SIBNET',
        'UQLOAD',
        'MYTV',
        'MYVI',
        'STREAMLARE',
        'MEGA',
        'DOOD',
        'FEMBED',
        'MAIL2',
        'OK',
        'GTV',
        'PSTREAM',
        'VIDEOVARD',
      ],
    }
  },
  methods: {
    async sendData() {
      this.$store.commit('set', {
        name: 'addLink',
        id: this.id,
        provider: this.provider,
        stream: this.stream,
        source: this.source,
      })
      await this.axios({
        method: 'post',
        url: 'https://bultimate.tk/addLinkFilm',
        data: {
          id: this.id,
          provider: this.provider,
          stream: this.stream,
          source: this.source,
        },
        headers: { authorization: this.token },
      }).catch(e => {
        this.snackbar.text = e.toString()
        this.snackbar.color = 'error'
        this.snackbar.enable = true
      }).then(res => {
        if (res.data.error) {
          this.snackbar.text = res.data.error.toString()
          this.snackbar.color = 'error'
          this.snackbar.enable = true
        } else {
          this.snackbar.text = res.data.toString()
          this.snackbar.color = 'success'
          this.snackbar.enable = true
        }
      })
    },
  },
}
</script>
