<template>
  <v-row>
    <v-col
      cols="12"
      md="4"
    >
      <dashboard-congratulation-john></dashboard-congratulation-john>
    </v-col>
    <v-col
      cols="12"
      md="8"
    >
      <dashboard-statistics-card></dashboard-statistics-card>
    </v-col>

    <v-col
      cols="20"
      md="12"
    >
      <v-card>
        <v-card-title class="align-start">
          <span>Traffic :</span>
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            class="mt-n2 me-n3"
          >
          </v-btn>
        </v-card-title>
        <v-card-text>
          <!-- Chart -->
          <div id="chart">
            <VueApexCharts
              type="line"
              height="350"
              :options="chartOptions"
              :series="series"
            ></VueApexCharts>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline } from '@mdi/js'
import VueApexCharts from 'vue-apexcharts'

// demos
import DashboardCongratulationJohn from './components/DashboardCongratulationJohn.vue'
import DashboardStatisticsCard from './components/DashboardStatisticsCard.vue'

export default {
  components: {
    DashboardCongratulationJohn,
    DashboardStatisticsCard,
    VueApexCharts,
  },
  data() {
    return {
      series: [{
        name: "Nombre d'utilisateurs",
        data: [40, 28, 51, 42, 109, 100],
      }, {
        name: 'Vidéos vues par jours',
        data: [32, 45, 32, 34, 52, 120],
      }],
      chartOptions: {
        chart: {
          height: 350,
          type: 'area',
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: false,
              selection: false,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: false,
              reset: true,
              customIcons: [],
            },
            autoSelected: 'reset',
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
        },
        xaxis: {
          type: 'datetime',
          categories: [ // new Date(1331209044000).toISOString();
            '2018-09-19T01:30:00.000Z',
            '2018-09-19T02:30:00.000Z',
            '2018-09-19T03:30:00.000Z',
            '2018-09-19T04:30:00.000Z',
            '2018-09-19T05:30:00.000Z',
            '2018-09-19T06:30:00.000Z',
          ],
        },
        tooltip: {
          x: {
            format: 'dd/MM/yy HH:mm',
          },
        },
      },
    }
  },
  setup() {
    const totalProfit = {
      statTitle: 'Total Profit',
      icon: mdiPoll,
      color: 'success',
      subtitle: 'Weekly Project',
      statistics: '$25.6k',
      change: '+42%',
    }

    const totalSales = {
      statTitle: 'Refunds',
      icon: mdiCurrencyUsd,
      color: 'secondary',
      subtitle: 'Past Month',
      statistics: '$78',
      change: '-15%',
    }

    // vertical card options
    const newProject = {
      statTitle: 'New Project',
      icon: mdiLabelVariantOutline,
      color: 'primary',
      subtitle: 'Yearly Project',
      statistics: '862',
      change: '-18%',
    }

    const salesQueries = {
      statTitle: 'Sales Quries',
      icon: mdiHelpCircleOutline,
      color: 'warning',
      subtitle: 'Last week',
      statistics: '15',
      change: '-18%',
    }

    return {
      totalProfit,
      totalSales,
      newProject,
      salesQueries,
    }
  },
}
</script>
