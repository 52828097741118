<template>
  <v-card
    flat
    class="mt-5"
  >
    <v-form>
      <div class="px-3">
        <v-card-text class="pt-5">
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
            >
              <v-text-field
                v-model="token"
                :type="isCurrentTokenVisible ? 'text' : 'password'"
                :append-icon="isCurrentTokenVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                label="Token"
                outlined
                dense
                data-form-type="text"
                @click:append="isCurrentTokenVisible = !isCurrentTokenVisible"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="8"
              md="6"
            >
              <v-btn
                depressed
                color="primary"
                @click="saveToken"
              >
                Sauvegarder
              </v-btn>
            </v-col>

            <v-col
              cols="12"
              sm="8"
              md="6"
            >
              <v-text-field
                v-model="cloudflare"
                :type="isCurrentCloudFlareVisible ? 'text' : 'password'"
                :append-icon="isCurrentCloudFlareVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                label="Cloudflare"
                outlined
                dense
                data-form-type="text"
                @click:append="isCurrentCloudFlareVisible = !isCurrentCloudFlareVisible"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="8"
              md="6"
            >
              <v-btn
                depressed
                color="primary"
                @click="saveCloudflare"
              >
                Sauvegarder
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </div>
    </v-form>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiKeyOutline, mdiLockOpenOutline, mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  data() {
    return {
      token: localStorage.getItem('token') || null,
      cloudflare: localStorage.getItem('cloudflare') || null,
    }
  },
  methods: {
    saveToken() {
      localStorage.setItem('token', this.token)
    },
    saveCloudflare() {
      localStorage.setItem('cloudflare', this.cloudflare)
    },
  },
  setup() {
    const isCurrentTokenVisible = ref(false)
    const isCurrentCloudFlareVisible = ref(false)

    return {
      isCurrentTokenVisible,
      isCurrentCloudFlareVisible,
      icons: {
        mdiKeyOutline,
        mdiLockOpenOutline,
        mdiEyeOffOutline,
        mdiEyeOutline,
      },
    }
  },
}
</script>
